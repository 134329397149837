"use client";

import axios from "axios";
import "source-map-support/register";
import { useEffect } from "react";
import { useRouter } from "next/navigation";

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const router = useRouter();

  useEffect(() => {
    axios
      .post("/api/log-errors", {
        message: error.message,
        stack: error.stack,
        componentStack: error?.digest,
      })
      .catch((err) => console.error("Failed to log error:", err));

    axios.post(
      "/api/log-errors",
      {
        message: error.message,
        stack: error.stack,
        componentStack: error?.digest,
      },
      { headers: { "Content-Type": "application/json" } }
    );
  }, [error]);

  return (
    <section className="flex h-full flex-col items-center justify-center">
      <h2 className="text-center">Ups! Algo falló!</h2>
      <button
        className="mt-4 rounded-md bg-blue-500 px-4 py-2 text-sm text-white transition-colors hover:bg-blue-400"
        onClick={() => router.push("/dashboard")}
      >
        Volver al inicio
      </button>
    </section>
  );
}
